import React, {useEffect} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {Link} from "gatsby";
import {adjustSearchParams} from "../components/helper-functions";


const JobDescription = ({pageContext}) => {
    let isInternal = process.env.INT_EXT === 'internal';
    useEffect(() => {

        let sources = ["jobillico", "jobboom"]
        let url = new URL(pageContext.url);
        for (let source in sources) {
            if (document.referrer.includes(sources[source])) {
                url.searchParams.set("source", sources[source])
                document.getElementById("pos_url_2").setAttribute("href", url.href)
                document.getElementById("pos_url_1").setAttribute("href", url.href)
                return;
            }
        }
        let windowUrl = new URL(window.location.href);
        if (windowUrl.searchParams.has("source")) {
            let applyButton1 = document.getElementById("pos_url_1");
            let applyButton2 = document.getElementById("pos_url_2");
            let href = applyButton1.getAttribute("href");
            url.searchParams.set("source", windowUrl.searchParams.get("source"))
            applyButton1.href = url;
            applyButton2.href = url;
        }
    }, [])

    return (<>
        {/*//Forklift Operator - Gatineau, QC | Rona*/}
        <Header title={`${pageContext.title} - ${pageContext.city}, ${pageContext.province} | ${pageContext.banner}`}
                description={pageContext.lang === "FR" ? `Postulez dès maintenant et rejoignez l’équipe ${pageContext.banner} ${pageContext.city} en tant qu’${pageContext.title}.` : `Apply now and join the ${pageContext.banner} ${pageContext.city} team as a ${pageContext.title}`}
                hidden={false}
                lang={pageContext.lang}
                breadcrumbs={[{
                    text: pageContext.title, url: pageContext.posPath
                }]}
                lang_link={pageContext.lang === "FR" ? `${pageContext.EN_URL}` : `${pageContext.FR_URL}`}
                bodyClasses={"page-index"}/>
        <main id={"main"} className={isInternal ? "internal" : ""}>
            <section className={"container-xxl mb5"}>
                <Link to={pageContext.lang === "FR"  ? '/recherche/' : "/search/"}>
                    <button className={"r ac jc button"}><i
                        style={{fontSize: "15px", marginTop: "2px", lineHeight: "20px"}}
                        className="fas fa-arrow-left mr3"/>{pageContext.lang === "FR" ? "Retourner" : "Back"}
                    </button>
                </Link>
            </section>
            <section className={"container-lg p4"}>
                <h1 className={"mb4"}>{pageContext.title}</h1>
                <div class={"c"}>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Enseigne" : "Banner"}: &nbsp;</b>
                        <p>{pageContext.banner}</p></div>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Entité" : "Entity"}: &nbsp;</b>
                        <p>{pageContext.banner} - {pageContext.storeNumber} - {pageContext.city}</p></div>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Ville" : "City"}: &nbsp;</b>
                        <p>{pageContext.city}</p></div>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Province" : "Province"}: &nbsp;</b>
                        <p>{pageContext.province}</p></div>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Statut du poste" : "Job Status"}: &nbsp;</b>
                        <p>{(() => {
                            let jobStatus = pageContext.jobStatus.toString();
                            return adjustSearchParams(jobStatus, pageContext.lang);
                        })()}</p></div>
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Catégorie d'emploi " : "Job Category"}: &nbsp;</b>
                        <p>{(() => {
                            let category = pageContext.jobCategory.toString();
                            return adjustSearchParams(category, pageContext.lang);
                        })()}
                        </p></div>
                    {(pageContext.remote === "na" || pageContext.remote.includes("Not Available")) ? "" :
                        <div class={"r"}>
                            <b>{pageContext.lang === "FR" ? "Travail à distance" : "Remote Work"}: &nbsp;</b>
                            <p>{adjustSearchParams(pageContext.remote, pageContext.lang)}</p>
                        </div>}
                    <div class={"r"}><b>{pageContext.lang === "FR" ? "Date de clôture" : "Closing Date"}: &nbsp;</b>
                        <p>{pageContext.closingDate}</p></div>
                </div>
                <a target={"_blank"} id="pos_url_1" className={""} rel={"nofollow"} href={pageContext.formUrl}>
                    <button className={"button"}>{pageContext.lang === "FR" ? "Postuler ici " : "Apply Here"}</button>
                </a>
                <div dangerouslySetInnerHTML={{__html: pageContext.description}}/>
                <a target={"_blank"} id="pos_url_2" rel={"nofollow"} href={pageContext.formUrl}>
                    <button className={"button"}>{pageContext.lang === "FR" ? "Postuler ici " : "Apply Here"}</button>
                </a>
            </section>
        </main>
        <Footer lang={pageContext.lang}/>
    </>)
}


export default JobDescription;