import L from "leaflet"

const distance = (lat1, lon1, lat2, lon2, unit) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // in metres
    return d / 1000
}
const makeRandomKey = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const positionUrl = (job, store, lang, head, path, locale, source) => {
  let parentPath = lang === "FR" ? "/carrieres/" : "/careers/";
  let city,
    state = "na";
  if (store.hasOwnProperty("city")) city = store["city"].toString();
  if (store.hasOwnProperty("province"))
    state =
      lang === "FR"
        ? store["province_fr"].toString()
        : store["province"].toString();
  let url =
    parentPath +
    head +
    "-" +
    city +
    "-" +
    state +
    "-" +
    path.split("-")[0].replace("/processes/", "") +
    "-" +
    locale;
  url = url.toLowerCase();
  url = url.replaceAll("-/ ", "-");
  url = url.replaceAll("-/ ", "-");
  url = url.replaceAll("   ", "-");
  url = url.replaceAll("  ", "-");
  url = url.replaceAll(" ", "-");
  url = url.replaceAll(", ", "-");
  url = url.replaceAll(",", "");
  url = url.replaceAll("(", "");
  url = url.replaceAll("(", "");
  url = url.replaceAll(")", "");
  url = url.replaceAll("---", "-");
  url = url.replaceAll("--", "-");
  url = url.replaceAll("-/-", "-");
  url = url.replaceAll("-&-", "-");
  url = removeAccents(url);
  if (source) {
    url = url + "/?source=" + source;
  }
  return url;
};

const createPopups = (feature = {}, layer) => {
    const {properties = {}} = feature
    const {storeNumber, jobs, city, entity, type, path, language, banner} = properties
    const popup = L.popup()

    //Track which markers are being actively displayed on the map
    let jobsHtml = ``;
    for (let job in jobs) {
        if (job > 2) {
            continue;
        }

        jobsHtml += `
            <li class="${job % 2 === 0 ? "c-odd" : "c-even"}">
                <a class="r ac jsb" href="${jobs[job].position_url}">
                   <h4 class="c">${jobs[job].headline.toString()}</h4>
                      <p>${jobs[job].jobCategory ? adjustSearchParams(jobs[job].jobCategory.toString(), process.env.LANG) : "n/a"}</p>
                      <p>${jobs[job].jobStatus ? adjustSearchParams(jobs[job].jobStatus.toString(), process.env.LANG) : "n/a"}</p>
                </a>
            </li>`
    }
    const opening = process.env.LANG === "FR" ? "Ouverture" : "Opening";
    const openings = process.env.LANG === "FR" ? "Ouvertures" : "Openings";
    const searchPath = process.env.LANG === "FR" ? "recherche" : "search";
    const loadMoreText = process.env.LANG === "FR" ? "Voir tous les emplois à cet endroit ≫" : "Load all jobs at this location ≫";
    const html = `
        <div class="popup-container">
            <h3 class="popup-header">
                <a target="_blank" href="/${searchPath}/?term=${storeNumber.toString()}">${entity.toString()}</a>
            </h3>
            <p class="openings">${jobs.length} - ${jobs.length === 1 ? opening : openings}</p>
            <ul class="job-list"> 
                ${jobsHtml}
            </ul>
            <p class="load-all-jobs">
                <a style="${process.env.LANG === "FR" ? "font-size:15px;": ""}" href="/${searchPath}/?term=${storeNumber.toString()}">${loadMoreText}</a>
            </p>
        </div>`
    popup.setContent(html)
    layer.bindPopup(popup,{offset:[0, 0]});
}
const createClusters = (cluster) => {
    const childCount = cluster.getChildCount()
    let size = ""
    if (childCount < 10) {
        size = "small"
    } else if (childCount < 25) {
        size = "medium"
    } else {
        size = "large"
    }
    return L.divIcon({
        html: `<div class="c jc ac">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21.992 24">
                    <path id="Path_Copy" data-name="Path Copy" d="M9.069,3.929,11.018,0l1.935,3.786c.241.4.437.367.823.176l1.664-.814L14.357,8.434c-.226,1.038.371,1.343,1.019.638l2.372-2.509.63,1.42c.211.429.53.367.952.29l2.45-.51-.823,3.063v.066c-.1.4-.289.734.163.928l.871.429-5.07,4.23c-.515.525-.337.686-.145,1.277l.467,1.42-4.71-.844c-.582-.143-.986-.143-1,.319L11.726,24H10.31l.193-5.334c0-.525-.4-.51-1.356-.316l-4.369.829.563-1.42c.193-.543.245-.91-.193-1.277L0,12.312l.952-.576c.274-.209.289-.429.145-.9L.13,7.741l2.483.525c.693.161.886,0,1.064-.367l.693-1.405L6.823,9.208c.434.51,1.049.176.856-.558L6.5,2.92,8.324,3.958c.289.176.6.224.775-.11" fill="#fff"/>
                </svg>
                <span>
                    <b>${childCount}</b>
                </span>
            </div>`, className: `custom-marker-cluster custom-marker-cluster-${size}`, iconSize: new L.point(54, 54),
    })
}
const adjustSearchParams = (value, lang) => {
    let param = value.toString();
    if (param.includes(" // ")) {
        if (lang === "FR") {
            let options = param.split(' // ');
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return "N/A"
            }
        } else {
            let options = param.split(' // ');
            if (options.length >= 1) {
                return options[0];
            } else {
                return "N/A"
            }
        }
    } else if (param.includes(" / ")) {
        if (lang === "FR") {
            let options = param.split(' / ');
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return "N/A"
            }
        } else {
            let options = param.split(' / ');
            if (options.length >= 1) {
                return options[0];
            } else {
                return "N/A"
            }
        }

    } else {
        if (param) {
            return param;
        } else {
            return "N/A"

        }
    }
}
const removeAccents = (str) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = "";
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes(str[char])) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};


export default distance;
export {distance, makeRandomKey, createPopups, createClusters, adjustSearchParams, removeAccents, positionUrl};

